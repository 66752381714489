<template>
    <div class="grid grid-cols-3 gap-2">
        <div class="card-tail self-start">
            <div class="border-b pb-2 mb-4">
                <h1>Detaljer</h1>
            </div>
            <div class="px-16">
                <table class="w-full">
                    <tr class="border-b">
                        <td class="py-2">Belopp</td>
                        <td class="py-2" align="right">{{ fileData.amount | swedishNumberFormat }} SEK</td>
                    </tr>
                    <tr class="border-b">
                        <td class="py-2">Bokningsdatum</td>
                        <td class="py-2" align="right">{{ formatDate(fileData.bookingDate) }}</td>
                    </tr>
                    <tr class="border-b">
                        <td class="py-2">Klient</td>
                        <td class="py-2" align="right">{{ fileData.clientNumber }}<span v-show="fileData.clientNumber || fileData.clientName">,</span> {{ fileData.clientName }}</td>
                    </tr>
                    <tr class="border-b">
                        <td class="py-2">Originalbelopp</td>
                        <td class="py-2" align="right">{{ fileData.originalAmount | swedishNumberFormat }}</td>
                    </tr>
                    <tr class="border-b">
                        <td class="py-2">Ursprunglig valuta</td>
                        <td class="py-2" align="right">{{ fileData.originalCurrency }}</td>
                    </tr>
                    <tr class="border-b">
                        <td class="py-2">Text</td>
                        <td class="py-2" align="right">{{ fileData.text }}</td>
                    </tr>
                    <tr>
                        <td class="py-2">Typ</td>
                        <td class="py-2" align="right">{{ fileData.type }}</td>
                    </tr>
                </table>
            </div>
        </div>
        <Commets :source-id="bankFileId" />
        <RefundContainer :bank-file-id="bankFileId" />
    </div>
</template>
<script>
import moment from "moment";
import Api from "./bank_files.api";

export default {
    data() {
        return {
            bankFileId: this.$route.params.fileId,
            fileData: {},
        };
    },

    components: {
        Commets: () => import("./components_details/Comments.vue"),
        RefundContainer: () => import("./components_details/BankFilesRefund.vue"),
    },

    beforeCreate() {
        this.$store.commit("setTopbarTitle", "Bank filer");
        document.title = "Bank filer";
    },

    created() {
        this.getDetails();
    },

    methods: {
        async getDetails() {
            this.fileData = await Api.getDetails(this.bankFileId);
            const clientName = this.fileData.clientName !== null ? this.fileData.clientName : "missing";
            document.title = "Bank filer - " + clientName;
        },

        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },
    },
};
</script>
