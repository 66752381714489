import Vue from "vue";

export default {
    getAll: async (pageNumber, pageSize, clientId, sortBy, sortType) => {
        return await Vue.axios.get(`clientFundsAccounts`, {
            params: {
                pageNumber,
                pageSize,
                clientId,
                sortBy,
                sortType,
            },
        });
    },

    getDetails: async id => {
        const { data } = await Vue.axios.get(`clientFundsAccounts/${id}`);
        return data;
    },

    getChildren: async id => {
        const { data } = await Vue.axios.get(`clientFundsAccounts/${id}/subs`);
        return data;
    },
    getChild: async (id, subId) => {
        const { data } = await Vue.axios.get(`clientFundsAccounts/${id}/subs/${subId}`);
        return data;
    },

    getClients: async () => {
        const { data } = await Vue.axios.get(`clientFundsAccounts/clients`);
        return data;
    },

    uploadFile: async files => {
        const { data } = await Vue.axios.post(`clientFundsAccounts/upload`, files, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        });
        return data;
    },

    getCommentsBySourceId: async id => {
        const { data } = await Vue.axios.get(`comments`, {
            params: {
                commentSource: 0,
                sourceId: id,
            },
        });
        return data;
    },

    addNewComment: async (id, text) => {
        const { data } = await Vue.axios.post(`comments`, {
            commentSource: 0,
            sourceId: id,
            text: text,
        });
        return data;
    },

    getAccountTypes: async () => {
        try {
            const { data } = await Vue.axios.get(`refunds/accountTypes`);
            return data;
        } catch (error) {
            console.log("🚀 ~ file: bank_files.api.js ~ line 58 ~ getAccountTypes: ~ error", error);
            return [];
        }
    },

    saveNewDraft: async (paymentId, refundData) => {
        try {
            const { data } = await Vue.axios.post(`refunds/${paymentId}`, {
                reason: refundData.reason,
                accountType: refundData.accountType,
                accountNumber: refundData.accountNumber,
                recipientName: refundData.recipientName,
                contactPerson: refundData.contactPerson,
                currency: refundData.currency,
                message: refundData.message,
                comment: refundData.comment,
                sendPain: 0,
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: bank_files.api.js ~ line 99 ~ saveRefund: ~ error", error);
            return [];
        }
    },

    saveDraft: async (paymentId, refundData) => {
        try {
            const { data } = await Vue.axios.put(`refunds/${paymentId}`, {
                reason: refundData.reason,
                accountType: refundData.accountType,
                accountNumber: refundData.accountNumber,
                recipientName: refundData.recipientName,
                contactPerson: refundData.contactPerson,
                currency: refundData.currency,
                message: refundData.message,
                comment: refundData.comment,
                sendPain: 0,
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: bank_files.api.js ~ line 99 ~ saveRefund: ~ error", error);
            return [];
        }
    },

    sendRefund: async (paymentId, refundData) => {
        try {
            const { data } = await Vue.axios.put(`refunds/${paymentId}`, {
                reason: refundData.reason,
                accountType: refundData.accountType,
                accountNumber: refundData.accountNumber,
                recipientName: refundData.recipientName,
                contactPerson: refundData.contactPerson,
                currency: refundData.currency,
                message: refundData.message,
                comment: refundData.comment,
                sendPain: 2,
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: bank_files.api.js ~ line 69 ~ sendRefund: ~ error", error);
            return [];
        }
    },

    markAsSent: async (paymentId, refundData) => {
        try {
            const { data } = await Vue.axios.put(`refunds/${paymentId}`, {
                reason: refundData.reason,
                accountType: refundData.accountType,
                accountNumber: refundData.accountNumber,
                recipientName: refundData.recipientName,
                contactPerson: refundData.contactPerson,
                currency: refundData.currency,
                message: refundData.message,
                comment: refundData.comment,
                sendPain: 1,
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: bank_files.api.js ~ line 99 ~ saveRefund: ~ error", error);
            return [];
        }
    },

    getRefund: async paymentId => {
        try {
            const { data } = await Vue.axios.get(`refunds/${paymentId}`);
            return data;
        } catch (error) {
            console.log("🚀 ~ file: bank_files.api.js ~ line 91 ~ getRefund: ~ error", error);
            return [];
        }
    },

    deleteRefund: async paymentId => {
        try {
            await Vue.axios.delete(`refunds/${paymentId}`);
        } catch (error) {
            console.log("🚀 ~ file: bank_files.api.js ~ line 100 ~ error", error);
        }
    },
    setClient: async (id, subId, clientId) => {
        try {
            const { data } = await Vue.axios.put(`clientFundsAccounts/${id}/subs/${subId}`, {
                clientId,
            });
            return data;
        } catch (error) {
            console.log("🚀 ~ file: bank_files.api.js ~ line 100 ~ error", error);
        }
    },
};
